import { graphql, PageProps } from "gatsby";
import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@material-ui/core";
import { Link } from "../components/link";

import Layout from "../layouts/layout";
import SEO from "../components/seo";
import SayingList from "../components/sayingList";
import Breadcrumbs from "@mui/material/Breadcrumbs";

export default function PersonPage({ data }) {
  const { mysqlPerson } = data;
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  const col = isLg ? 3 : isSm ? 2 : 1;
  return (
    <Layout>
      <>
        <Box style={{ padding: "4px 0", overflow: "scroll" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/">トップ</Link>
            <Typography>{`${mysqlPerson.name}の名言集`}</Typography>
          </Breadcrumbs>
        </Box>
        <SEO
          canonical={`https://marutto-meigen.com/persons/${mysqlPerson.mysqlId}`}
          title={`${mysqlPerson.name}の名言集 |【まるっと名言集】偉人やアニメなどの名言を抜粋`}
          description={mysqlPerson.description}
        />
        <Typography
          component="h1"
          style={{ margin: "12px 0", fontWeight: "bold" }}
        >
          {mysqlPerson.affiliation == null ? (
            <>{mysqlPerson.name}の名言集</>
          ) : (
            <>
              {mysqlPerson.name}の名言集 -{" "}
              <Link
                to={`/affiliations/${mysqlPerson.affiliation.mysqlId}/page/1`}
              >
                {mysqlPerson.affiliation.name}
              </Link>
            </>
          )}
        </Typography>
        {mysqlPerson.description && (
          <Typography
            style={{
              whiteSpace: "pre-wrap",
              backgroundColor: "#f3f3f3",
              color: "#222",
              padding: "12px",
              margin: "18px 0",
            }}
          >
            {mysqlPerson.description}
          </Typography>
        )}
        <SayingList col={col} sayings={mysqlPerson.sayings} />
      </>
    </Layout>
  );
}

export const query = graphql`
  query PersonTemplate($id: Int!) {
    mysqlPerson(mysqlId: { eq: $id }) {
      name
      mysqlId
      description
      sayings {
        mysqlId
        content
        person {
          mysqlId
          name
        }
      }
      affiliation {
        mysqlId
        name
      }
    }
  }
`;

export function Head({ data }) {
  const { mysqlPerson } = data;

  const jsonLd = `{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "トップ",
        "item": "https://marutto-meogen.com"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "${mysqlPerson.name}の名言集",
        "item": "https://marutto-meigen.com/persons/${mysqlPerson.mysqlId}"
      }
    ]
  }`;
  return (
    <>
      <script type="application/ld+json">{jsonLd}</script>
    </>
  );
}
